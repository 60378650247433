<template>
	<div class="gonghai">
		<div class="top1">
			<div></div>
			<span>公海管理</span>
		</div>
		<div class="tablebox">
			<div class="tabletop">
				<div class="inputbox">
					<input  placeholder="公司名称搜索" v-model="searchInfo.customerName"/>
					<div class="flex"><i class="el-icon-search" style="color: #AAAAAA;"></i></div>
				</div>
				<div class="selectbox">
					<span>公司类型</span>
					<el-select v-model="searchInfo.companyType" placeholder="请选择">
						<el-option
							v-for="item in companyTypeList"
							:key="item.settingId"
							:label="item.settingName"
							:value="item.settingId"
						></el-option>
					</el-select>
				</div>
				<div class="chaxun flex nochoosetext" style="margin-left: 2.5em;" @click="_customerList">
					<span>查询</span>
				</div>
				<div class="chaxun flex nochoosetext" @click="Reset"><span>重置</span></div>
			</div>
		<!-- 	<div class="addbox">
				<i class="el-icon-plus" style="color: #ffffff;"></i>
				<span>新增</span>
			</div> -->
			<div class="allcountbox">
				共筛选出 <span style="color: #3266F7;">{{totalCount}}</span> 条公海记录
			</div>

			<div style="padding: 1.25em;">
				<el-table :data="tableData" border style="width: 100%" @row-click="clickRow">
					<el-table-column prop="customerName" label="公司名称"></el-table-column>
					<el-table-column label="联系人">
						<template slot-scope="scope">
							<div class="allpersonal">
								<div class="" v-for="(item,index) in scope.row.contactVo" :key="index">
									姓名: {{item.userName}}  电话: {{item.contactPhone || '暂无'}}
								</div>
							</div>
							
						</template>
						
					</el-table-column>
					<el-table-column prop="companyTypeName" label="公司类型"></el-table-column>
					<!-- <el-table-column prop="address" label="位置信息">
						<template slot-scope="scope">
							<div class="textbox">
								{{scope.row.address}}
							</div>
						</template>
						
					</el-table-column> -->
					<!-- <el-table-column prop="customerDesc" label="公司简介">
						<template slot-scope="scope">
							<div class="textbox">
								{{scope.row.customerDesc}}
							</div>
						</template>
					</el-table-column> -->
					<el-table-column prop="customerSourceName" label="线索来源"></el-table-column>
					<el-table-column label="类型">
						<template slot-scope="scope">
							{{scope.row.status == 0 ? '线索' : scope.row.status == 1 ? '商机' : '客户'}}
						</template>
					</el-table-column>
					<el-table-column prop="seaTime" label="日期" ></el-table-column>
					<el-table-column label="操作">
						<template slot-scope="scope">
							<div class="twobtn">
								<div class="kanbox" v-if="permissionList.includes('gonghaiManage:detail') || isAdmin" @click.stop="goDetail(scope.row)">
									<img src="../../assets/btnicon/image7.png">
									看
								</div>
								<div class="kanbox ling" v-if="permissionList.includes('gonghaiManage:receive') || isAdmin" @click.stop="_receiveCustomer(scope.row.customerId)">
									<img src="../../assets/btnicon/image1.png">
									领
								</div>
							</div>
						</template>
					</el-table-column>
				</el-table>
				<div class="pagebox">
					<el-pagination
						background
						@size-change="handleSizeChange"
						@current-change="handleCurrentChange"
						:current-page="searchInfo.page"
						:page-sizes="[5, 10, 15, 20]"
						:page-size="searchInfo.pageSize"
						layout="total, sizes, prev, pager, next, jumper"
						:total="totalCount"
					></el-pagination>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import  {customerList,customerInfo,receiveCustomer} from "@/network/api.js"
	import { mapState } from "vuex"
export default {
	data() {
		return {
			totalCount: 0,
			searchInfo: {
				companyType: '',
				customerName: '',
				isAsc: 'asc',
				orderByColumn: '',
				page: 1,
				pageSize: 10,
				searchBody: '',
				inSea: 1,
				customerSource: '',
				customerStatus: '',
				isWait: '0',
				// type: 1   // 0 我的 1 团队
			},
			tableData: []
		};
	},
	name: 'gonghaiManager',
	computed: {
		...mapState('systemModule',{
			companyTypeList: 'companyTypeList'
		}),
		permissionList() {
			return this.$store.state.permissionList 
		},
		isAdmin() {
			return (this.$store.state.isadmin && localStorage.getItem('isStaff') == '0')
		},
	},
	created() {
		this._customerList()
	},
	methods: {
		// 点击行数据
		clickRow(val) {
			if (this.permissionList.includes('gonghaiManage:detail') || this.isAdmin) {
				this.goDetail(val)
			}
		},
		// 点击重置
		Reset() {
			this.searchInfo.companyType = ''
			this.searchInfo.searchBody = ''
		},
		// 领取公海客户
		async _receiveCustomer(id) {
			const res = await receiveCustomer({
				customerId: id
			})
			this.$myMessage('领取成功','success')
			this._customerList()
		},
		// 获取公海列表
		async _customerList() {
			const res = await customerList(this.searchInfo)
			console.log("公海列表",res);
			this.tableData = res.data.list
			this.totalCount = res.data.totalCount
		},
		// 跳转详情
		goDetail(info) {
			
			if (info.status == 0) {
				this.$router.push({
					path: "/ghcluesdetail",
					query: {
						customerId: info.customerId
					}
				})
			} else if (info.status == 1) {
				this.$router.push({
					path: "/ghshangjidetail",
					query: {
						
						customerId: info.customerId
					}
				})
			} else if (info.status == 2) {
				this.$router.push({
					path: "/ghcustomerdetail",
					query: {
						
						customerId: info.customerId
					}
				})
			}
		},
	
		handleSizeChange(val) {
			this.searchInfo.pageSize = val
			this._customerList()
		},
		handleCurrentChange(val) {
			this.searchInfo.page = val
			this._customerList()
		}
	}
};
</script>

<style scoped lang="scss">
	.allcountbox {
		width: 100%;
		background-color: #EFF3F6;
		height: 3.25em;
		padding: 0 1.25em;
		font-size: 1em;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #5B6271;
		display: flex;
		align-items: center;
		span {
			margin: 0 0.3em;
		}
	}
	.allpersonal {
		color: #3266F7;
		max-height: 6.25em;
		overflow-y: scroll;
	}
	::-webkit-scrollbar {
		// display: none;
		width: 0.375em;
		// border-radius: 0.1875em;
		// height: 1.25em;
		background-color: rgb(255, 255, 255);
	}
	::-webkit-scrollbar-thumb {
		border-radius: 0.1875em;
		background-color: rgb(172, 172, 172);
	}
	.textbox {
		 text-overflow: -o-ellipsis-lastline;
		  overflow: hidden;
		  text-overflow: ellipsis;
		  display: -webkit-box;
		  -webkit-line-clamp: 2;
		  line-clamp: 2;
		  -webkit-box-orient: vertical;
	}
	::v-deep {
		.pagebox {
			width: 100%;
			display: flex;
			justify-content: flex-end;
			margin: 6.25em 0 0 1.25em;
			padding: 0 1.25em 0 0;
			.el-input__inner {
				color: #666666;
				width: 3em;
				height: 2.25em;
				line-height: 2.25em;
			}
			.el-select {
				.el-input__inner {
					color: #666666;
					width: 8em;
					height: 2.25em;
					line-height: 2.25em;
				}
			}
			
			.el-input__icon {
				line-height: 2.25em;
			}
		}
	}
.kanbox:active {
	opacity: 0.6;
}
.kanbox {
	cursor: pointer;
	width: 3.625em;
	height: 1.625em;
	background: #0091ff;
	border-radius: 0.8125em;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 0.675em;
	font-size: 0.875em;
	font-family: PingFang-SC-Medium, PingFang-SC;
	font-weight: 500;
	color: #ffffff;
	img {
		width: 1em;
		height: 0.9375em;
	}
	&.ling {
		background-color: #3dc57c;
		margin: 0 0 0 1.25em;
		img {
			width: 0.875em;
			height: 0.9375em;
		}
	}
	img {
		width: 0.9375em;
		height: 0.9375em;
	}
}
.twobtn {
	display: flex;
	align-items: center;
	justify-content: center;
}
::v-deep {
	.cell {
		text-align: center;
	}
	tr {
		font-size: 0.975em;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 400;
		color: #868e99;
	}
	th {
		background-color: #f6f7fb !important;
		font-size: 0.975em;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #5b6271;
	}
}
thead {
}
.addbox:active {
	opacity: 0.6;
}
.addbox {
	margin: 1.5625em 0 1.25em 0;
	cursor: pointer;
	width: 6.75em;
	height: 2.25em;
	background: linear-gradient(270deg, #2d74f4 0%, #3286ff 100%);
	border-radius: 0.25em;
	display: flex;
	align-items: center;
	justify-content: center;
	span {
		font-size: 0.875rem;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #ffffff;
		margin: 0 0 0 1.25em;
	}
}
::v-deep {
	.el-button {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.chaxun:active {
	background-color: rgb(234,242,255);
	color: #2F79FF;
	border: 1px solid #2F79FF;
}
.chaxun {
	cursor: pointer;
	margin: 0 0 0 1.25em;
	width: 5.625em;
	height: 2.25em;
	border-radius: 0.25em;
	border: 1px solid #DDDDDD;
	font-weight: 400;
	color: #333333;
	font-family: PingFang-SC-Medium, PingFang-SC;
	span {
		font-size: 0.875em;
	}
}
.tabletop {
	display: flex;
	align-items: center;
	padding: 2em 1.25em 1.25em;
}
::v-deep {
	.el-input__inner {
		color: #666666;
		width: 10.5em;
		height: 2.25em !important;
		line-height: 2.25em;
	}
	.el-input__icon {
		line-height: 2.25em;
	}
}
.selectbox {
	margin: 0 0 0 2.5em;
	display: flex;
	align-items: center;
	span {
		font-size: 0.875em;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		margin: 0 0.6875em 0 0;
	}
}
.tablebox {
	margin: 0.9375em 0 0 0;
	background-color: #ffffff;
	// padding: 1rem 1.25em;
}
.inputbox {
	display: flex;
	align-items: center;
	background: #ffffff;
	border-radius: 0.25em;
	border: 1px solid #e4e4e4;
	height: 2.25em;
	width: 12.5em;
	justify-content: space-between;
	input {
		outline: none;
		border: 0;
		width: 10.25em;
		font-size: 0.875em;
		padding: 0 0.375em 0 0.875em;
	}
	div {
		width: 2.25em;
		height: 2.25em;
		background: #faf9fb;
		border-radius: 0px 0.25em 0.25em 0px;
		border: 1px solid #e4e4e4;
	}
}
.top1 {
	width: 100%;
	height: 3.25em;
	background-color: #ffffff;
	display: flex;
	align-items: center;
	padding: 0 1.0625em;
	div {
		width: 0.1875em;
		height: 1.125em;
		background: linear-gradient(270deg, #0463ec 0%, #3286ff 100%);
		border-radius: 0.125em;
	}
	span {
		font-size: 1.125em;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		margin: 0 0 0 0.625em;
		color: #303030;
	}
}
.gonghai {
	padding: 0.9375em;
}
@media screen and (max-width: 1920px) {
	.gonghai {
		font-size: 16px;
	}
}
@media screen and (max-width: 1720px) {
	.gonghai {
		font-size: 15px;
	}
}
@media screen and (max-width: 1520px) {
	.gonghai {
		font-size: 14px;
	}
}
@media screen and (max-width: 1320px) {
	.gonghai {
		font-size: 13px;
	}
}
@media screen and (max-width: 1120px) {
	.gonghai {
		font-size: 12px;
	}
}
</style>
